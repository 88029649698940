const keys = {
  stripe: {
    secretKey: process.env.NEXT_PUBLIC_STRIPE_SECRET_KEY,
    publicKey: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY,
    webhookSecret: process.env.NEXT_PUBLIC_STRIPE_WEBHOOK_SECRET,
    monthlyPriceId: process.env.NEXT_PUBLIC_STRIPE_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_STRIPE_YEARLY_PRICE_ID,
  },
  perplexity: {
    secretKey: process.env.PERPLEXITY_API_KEY,
  },

  redis: {
    uri: process.env.REDIS_URI,
  },
}

export default keys
