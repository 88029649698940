import { css, jsx } from '@emotion/react'
import { useSetRecoilState } from 'recoil'
import { useState } from 'react'
import useKeys from '@rooks/use-keys'
import { Button } from './chakra/Button'
import Logo from './Logo'
import { Text } from './chakra/Text'
import Emoji from './Emoji'
import VerticalSpace from './VerticalSpace'
import Div from './Div'
import {
  sidebarWidth,
  openFoldersNav,
  dashboardActiveItem,
  tagsSortMode,
  bookmarksSortMode,
  bookmarksSearchMode,
  tagsSearchMode,
  bookmarksViewMode,
  tagsViewMode,
  folders,
  bookmarks,
  tags,
} from '../store'
import { sidebarWidthDefault } from '../constants'
import { deleteData as deleteDataService } from '../services/delete'
import { analyticsTrack } from '../utils/analytics'
import { Toast } from './chakra/Toast'

const styles = {
  container: css`
    height: 100%;
  `,
}

function UncaughtAppError() {
  const setSidebarWidth = useSetRecoilState(sidebarWidth)
  const setOpenFoldersNav = useSetRecoilState(openFoldersNav)
  const setDashboardActiveItem = useSetRecoilState(dashboardActiveItem)
  const setBookmarksViewMode = useSetRecoilState(bookmarksViewMode)
  const setTagsViewMode = useSetRecoilState(tagsViewMode)
  const setTagsSortMode = useSetRecoilState(tagsSortMode)
  const setBookmarksSortMode = useSetRecoilState(bookmarksSortMode)
  const setBookmarksSearchMode = useSetRecoilState(bookmarksSearchMode)
  const setTagsSearchMode = useSetRecoilState(tagsSearchMode)

  const setFolders = useSetRecoilState(folders)
  const setBookmarks = useSetRecoilState(bookmarks)
  const setTags = useSetRecoilState(tags)
  const [deleting, setDeleting] = useState(false)

  useKeys(['Space', 'Backspace', 'g'], async event => {
    event.stopPropagation()
    alert(
      'It seems that you have some corrupted data that is causing GetBackTo to crash, you may want to delete your data to fix this, if you want to do so click OK then confirm the next alert.'
    )
    if (!window?.confirm('Do you really want to delete your data?')) return
    try {
      setDeleting(true)
      await deleteDataService(setBookmarks, setFolders, setTags)
      analyticsTrack('delete_data_from_uncaught_app_error')
      window.location.reload()
    } catch (e) {
      // eslint-disable-next-line no-console
      Toast({
        title: e.response?.data.message || `Error deleting data`,
        description: 'If the problem persists, contact support',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      })
      analyticsTrack('error_delete_data_from_uncaught_app_error', { error: e })
    } finally {
      setDashboardActiveItem('bookmarks|all')
      setDeleting(false)
    }
  })

  return (
    <Div center css={styles.container}>
      <div>
        <Div center>
          <Logo size={13} />
        </Div>
        <VerticalSpace />
        <div>
          <Text textAlign="center" block>
            <Emoji left>⚠</Emoji>OOPS<Emoji right>⚠</Emoji>
          </Text>
        </div>
        <div>
          <Text textAlign="center" block maxWidth="380px">
            Looks like you're trying to get into something we are still developing
          </Text>
        </div>
        <VerticalSpace space={5} />
        <Div flex>
          <Button
            colorScheme="accentedButton"
            lightMode
            margin="auto"
            isLoading={deleting}
            onClick={() => {
              // Reset storage to default
              setSidebarWidth(sidebarWidthDefault)
              setOpenFoldersNav({})
              setDashboardActiveItem('bookmarks|all')
              setBookmarksViewMode({
                viewLikeCard: true,
                showTags: true,
                showUrl: true,
                showDescription: true,
                showDate: false,
              })
              setTagsViewMode({
                showCount: true,
                showDescription: true,
                showDate: false,
              })
              setTagsSortMode('createdAt')
              setBookmarksSortMode('createdAt')
              setBookmarksSearchMode({
                title: true,
                description: true,
                url: true,
                tags: true,
                text: false,
              })
              setTagsSearchMode({
                name: true,
                description: true,
              })
              window.location.reload()
            }}
          >
            Restart
          </Button>
        </Div>
      </div>
    </Div>
  )
}

export default UncaughtAppError
